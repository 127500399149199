import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { ContentfulCtaBanner } from '../../generated/gatsby.types';
import extractCta from '../Cta';

const transformCtaSection = (item: ContentfulCtaBanner) => {
  return {
    __typename: 'CtaSection',
    key: item.id,
    title: item.title,
    sectionCategory: null,
    shortDescription: item?.description?.raw ? documentToPlainTextString(JSON.parse(item.description.raw)) : '',
    image: item.image,
    cta: extractCta(item),
    orientation: item?.orientation?.toLowerCase(),
  };
};

export default transformCtaSection;
