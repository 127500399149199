import extractShortDescription from '../ShortDescription';
import {
  ContentfulDealerConnection,
  ContentfulDealerEdge,
  ContentfulFindADealerSection,
} from '../../generated/gatsby.types';

const extractDealers = (dealers: Array<ContentfulDealerEdge>) => {
  return dealers.map((dealerEdge: ContentfulDealerEdge) => {
    const dealer = dealerEdge.node;
    return {
      title: dealer.title,
      address: dealer.address ? dealer.address.address : null,
      mapUrl: dealer.mapUrl,
      telephone: dealer.telephone ? dealer.telephone.telephone : null,
      latitude: dealer.location?.lat,
      longitude: dealer.location?.lon,
      filters: dealer.filters,
    };
  });
};

const transformFindADealerSection = (item: ContentfulFindADealerSection, dealers: ContentfulDealerConnection) => {
  const allFilters = dealers.edges.map(edge => [edge.node.filters]);
  // const flattenedFilters = allFilters
  //   .reduce((acc, val) => acc.concat(val), [])
  //   .reduce((acc, val) => acc.concat(val), []);
  const flattenedFilters = allFilters.flat().flat();
  const uniqueFilters = flattenedFilters.filter((value: any, index: any, self: any) => self.indexOf(value) === index);

  return {
    __typename: 'FindADealerSection',
    key: item.id,
    title: item.title,
    filterLabel: item.filterLabel,
    shortDescription: extractShortDescription(item),
    dealers: extractDealers(dealers.edges),
    categories: uniqueFilters,
  };
};

export default transformFindADealerSection;
