import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';

const transformInfoSection = (item: any, locale: string) => {
  return {
    __typename: 'ImageAndText',
    key: item.id,
    title: item.title,
    image: item.image,
    alignImageRight: item.alignImageRight,
    richText: {
      json: item.text?.richText ? richTextToJson(item.text?.richText, locale).json : null,
    },
    textAlignment: item.text?.alignment,
    videoUrl: item.video && item.video.videoUrl,
  };
};

export default transformInfoSection;
