import extractFileInforamtion from '../File';

const transformDocument = (item: any) => {
  return {
    __typename: 'DocumentDownloadSection',
    key: item.id,
    ...extractFileInforamtion(item),
  };
};

export default transformDocument;
