import { getImage } from 'gatsby-plugin-image';
import { ContentfulAdviceConnection, ContentfulAdviceEdge } from '../../generated/gatsby.types';
import extractShortDescription, { ItemType } from '../ShortDescription';

const extractAdvice = (adviceEdges: Array<ContentfulAdviceEdge>) => {
  return adviceEdges.map((edge: ContentfulAdviceEdge) => {
    const adviceItem = edge.node;

    return {
      title: adviceItem.title,
      shortDescription: extractShortDescription(adviceItem as ItemType),
      slug: adviceItem.slug,
      link: adviceItem.slug,
      image: getImage(adviceItem.image?.asset?.gatsbyImageData),
    };
  });
};

export const transformAdviceListingSection = (item: ItemType, adviceItems: ContentfulAdviceConnection) => {
  return {
    __typename: 'AdviceListingSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    advice: extractAdvice(adviceItems.edges),
    nextPageLink: '/',
  };
};

export default transformAdviceListingSection;
