/* eslint-disable no-underscore-dangle */
import React from 'react';
import { graphql } from 'gatsby';

import queryString from 'query-string';
import { Location } from '@reach/router';
import { BasicPage as BasicPageComponent } from '../ui-lib/components';
import {
  ContentfulSettings,
  ContentfulEventConnection,
  ContentfulNewsConnection,
  ContentfulDealerConnection,
  ContentfulAdviceConnection,
  ContentfulProductConnection,
  ContentfulAdvicesSection,
  ContentfulCookieBanner,
  ContentfulBasicPage,
  Site,
  ContentfulCategoryConnection,
  ContentfulBasicPageConnection,
} from '../generated/gatsby.types';
import * as Utils from '../utils';
import Layout from '../components/layout';

import tranformHeroSection from '../transforms/BasicPage/heroSection';
import transformInfoSection from '../transforms/BasicPage/InfoSection';
import transformUpcomingEventsSection from '../transforms/UpcomingEvents';
import transformLatestNewsSection from '../transforms/BasicPage/LatestNews';
import transformProductsServicesSection from '../transforms/ProductsServices';
import transformCtaSection from '../transforms/BasicPage/CtaSection';
import transformProductCategoriesSection from '../transforms/ProductCategories';
import transformTextSection from '../transforms/BasicPage/RichText';
import transformContactsSection from '../transforms/BasicPage/ContactsSection';
import transformFindADealerSection from '../transforms/BasicPage/FindADealerSection';
import transformSearchResultsSection from '../transforms/BasicPage/SearchResults';
import transformAdviceListingSection from '../transforms/BasicPage/AdviceListingSection';
import transformAdvicesSection from '../transforms/BasicPage/AdvicesSection';
import transformProductListingTableSection from '../transforms/BasicPage/ProductListingTable';
import transformNewsListingSection from '../transforms/NewsListing';
import transformEventsListingSection from '../transforms/BasicPage/EventsListing';
import transformPastEventsSection from '../transforms/BasicPage/PastEvents';
import transformProductListingSection from '../transforms/BasicPage/ProductListing';
import transformCategoryListingSection from '../transforms/BasicPage/CategoryListing';
import transformDocument from '../transforms/BasicPage/Document';
import transformImageAndText from '../transforms/BasicPage/ImageAndText';
import transformSubcategoryListingSection from '../transforms/BasicPage/SubcategoryListing';
import transformShortBanner from '../transforms/BasicPage/ShortBanner';
import transformMedia from '../transforms/BasicPage/Media';
import transformTable from '../transforms/BasicPage/Table';
import transformCustomBlocksSection from '../transforms/BasicPage/CustomBlocks';

type BasicPageQueryType = {
  contentfulBasicPage: ContentfulBasicPage;
  contentfulSettings: ContentfulSettings;
  allContentfulEvent: ContentfulEventConnection;
  allContentfulNews: ContentfulNewsConnection;
  allContentfulDealer: ContentfulDealerConnection;
  allContentfulAdvice: ContentfulAdviceConnection;
  allContentfulAdvicesSection: ContentfulAdvicesSection;
  allContentfulProduct: ContentfulProductConnection;
  allContentfulCategory: ContentfulCategoryConnection;
  contentfulCookieBanner: ContentfulCookieBanner;
  translatedSlugs: ContentfulBasicPageConnection;
  site: Site;
};

type PageContextType = {
  id: number;
  node_locale: string;
  isHomepage?: boolean;
};

type BasicPageProps = {
  data: BasicPageQueryType;
  pageContext: PageContextType;
};

const transformerMap: any = {
  'hero-section': tranformHeroSection,
  'info-section': transformInfoSection,
  'upcoming-events-section': transformUpcomingEventsSection,
  'events-listing-section': transformEventsListingSection,
  'past-events-section': transformPastEventsSection,
  'latest-news-section': transformLatestNewsSection,
  'products-services-section': transformProductsServicesSection,
  'cta-section': transformCtaSection,
  'cta-banner': transformCtaSection,
  'product-categories-section': transformProductCategoriesSection,
  'text-section': transformTextSection,
  'contacts-section': transformContactsSection,
  'find-a-dealer-section': transformFindADealerSection,
  'search-results-section': transformSearchResultsSection,
  'advice-listing-section': transformAdviceListingSection,
  'advice-section': transformAdvicesSection,
  'products-listing-table-section': transformProductListingTableSection,
  'news-listing-section': transformNewsListingSection,
  'product-listing-section': transformProductListingSection,
  'category-listing-section': transformCategoryListingSection,
  document: transformDocument,
  'image-and-text': transformImageAndText,
  'subcategory-listing-section': transformSubcategoryListingSection,
  'short-banner': transformShortBanner,
  media: transformMedia,
  table: transformTable,
  'custom-blocks-section': transformCustomBlocksSection,
};

const BasicPage = ({ data, pageContext }: BasicPageProps) => {
  const { title, contentsSection, image, updatedAt } = data.contentfulBasicPage;
  const modules = Utils.parseModules(contentsSection || []);

  return (
    <Location>
      {({ location }) => (
        <Layout
          pageLocale={pageContext.node_locale}
          pageType="basicpage"
          siteUrl={data.site.siteMetadata?.siteUrl || ''}
          pageSlug={data.contentfulBasicPage.slug ? data.contentfulBasicPage.slug : ''}
          contentfulSettings={data.contentfulSettings}
          pageSettings={data.contentfulBasicPage.metaTags}
          pageImage={image || undefined}
          cookieBannerTitle={data.contentfulCookieBanner?.title || ''}
          cookieBannerDescription={data.contentfulCookieBanner?.description}
          showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
          showFooterSubscribeFormCountryField={data.contentfulSettings.footerShowSubscribeFormCountryField || false}
          subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
          flexmailId={data.contentfulSettings.flexmailId}
          updatedAt={updatedAt}
          ogPageType="website"
          translatedSlugs={data.translatedSlugs?.nodes ?? []}
          isHomepage={pageContext.isHomepage ?? false}
        >
          <BasicPageComponent
            title={title || undefined}
            contentsSectionCollection={{
              items: modules
                .map(module => {
                  if (!module) {
                    return null;
                  }

                  if (module.type === 'upcoming-events-section') {
                    return transformerMap['upcoming-events-section'](module, data.allContentfulEvent);
                  }

                  if (module.type === 'events-listing-section') {
                    return transformerMap['events-listing-section'](module, data.allContentfulEvent);
                  }

                  if (module.type === 'past-events-section') {
                    return transformerMap['past-events-section'](module, data.allContentfulEvent);
                  }

                  if (module.type === 'latest-news-section') {
                    return transformerMap['latest-news-section'](module, data.allContentfulNews);
                  }

                  if (module.type === 'find-a-dealer-section') {
                    return transformerMap['find-a-dealer-section'](module, data.allContentfulDealer);
                  }

                  if (module.type === 'search-results-section') {
                    return transformerMap['search-results-section'](
                      module,
                      location.search ? queryString.parse(location.search) : {},
                      data.contentfulSettings,
                    );
                  }

                  if (module.type === 'advice-listing-section') {
                    return transformerMap['advice-listing-section'](module, data.allContentfulAdvice);
                  }

                  if (module.type === 'news-listing-section') {
                    return transformerMap['news-listing-section'](module, data.allContentfulNews);
                  }

                  if (module.type === 'product-listing-section') {
                    return transformerMap['product-listing-section'](
                      module,
                      data.allContentfulProduct,
                      location.search ? queryString.parse(location.search) : {},
                    );
                  }

                  if (module.type === 'category-listing-section') {
                    return transformerMap['category-listing-section'](module, data.allContentfulCategory);
                  }

                  if (module.type === 'contacts-section') {
                    return transformerMap['contacts-section'](
                      module,
                      data.contentfulSettings.contactsShowSubscribeForm,
                      pageContext.node_locale,
                    );
                  }

                  if (module.type === 'text-section') {
                    return transformerMap['text-section'](module, pageContext.node_locale);
                  }

                  if (module.type === 'info-section') {
                    return transformerMap['info-section'](module, pageContext.node_locale);
                  }

                  if (transformerMap[module.type]) {
                    return transformerMap[module.type](module);
                  }

                  return null;
                })
                .filter(Boolean),
            }}
          />
        </Layout>
      )}
    </Location>
  );
};

export default BasicPage;

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    translatedSlugs: allContentfulBasicPage(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }
    contentfulCookieBanner(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      title
      description {
        raw
      }
    }
    contentfulBasicPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      seoMetadata {
        id
        description
        legacyDescription {
          legacyDescription
        }
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }
      # TODO migrate to seoMetadata
      metaTags {
        id
        description
        nofollow
        title
        keywords
        canonical
        noindex
      }
      image {
        title
        alt: altText
        asset {
          # fixed(width: 1440, height: 600, resizingBehavior: FILL, quality: 85) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FULL_WIDTH, quality: 85, width: 1440, height: 600, resizingBehavior: FILL)
        }
        socialImage: asset {
          # fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FIXED, quality: 100, width: 1200, height: 630, resizingBehavior: CROP)
        }
      }
      contentsSection {
        __typename
        ... on ContentfulHeroSection {
          ...QueryHeroSection
        }
        ... on ContentfulInfoSection {
          ...QueryInfoSection
        }
        ... on ContentfulUpcomingEvents {
          ...QueryUpcomingEvents
        }
        ... on ContentfulLatestNewsSection {
          ...QueryLatestNewsSection
        }
        ... on ContentfulProductsServicesSection {
          ...QueryProductsServices
        }
        ... on ContentfulCtaBanner {
          ...QueryCtaBanner
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulProductCategoriesSection {
          ...QueryProductCategoriesSection
        }
        ... on ContentfulRichTextSection {
          ...QueryRichTextSection
        }
        ... on ContentfulContactsSection {
          ...QueryContactsSection
        }
        ... on ContentfulFindADealerSection {
          ...QueryFindADealerSection
        }
        ... on ContentfulSearchResultsSection {
          ...QuerySearchResultsSection
        }
        ... on ContentfulAdviceListingSection {
          ...QueryAdviceListingSection
        }
        ... on ContentfulProductsListingTableSection {
          ...QueryProductListingTable
        }
        ... on ContentfulNewsListingSection {
          ...QueryNewsListing
        }
        ... on ContentfulEventsListingSection {
          ...QueryEventsListing
        }
        ... on ContentfulPastEventsSection {
          ...QueryPastEventsSection
        }
        ... on ContentfulProductListingSection {
          ...QueryProductListingSection
        }
        ... on ContentfulCategoryListingSection {
          ...QueryCategoryListingSection
        }
        # TODO check if this may be removed
        ... on ContentfulAttachment {
          ...QueryDocument
        }
        ... on ContentfulImageAndText {
          ...QueryImageAndText
        }
        ... on ContentfulSubcategoryListingSection {
          ...QuerySubcategoryListingSection
        }
        ... on ContentfulShortBanner {
          ...QueryShortBanner
        }
        ... on ContentfulMediaBlock {
          ...QueryMediaBlock
        }
        ... on ContentfulTable {
          ...QueryTable
        }
        ... on ContentfulCustomBlocksSection {
          ...QueryCustomBlocksSection
        }
        ... on ContentfulAdvicesSection {
          ...QueryAdvicesSection
        }
      }
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }

    allContentfulEvent(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          id
          # TODO discuss how to migrate to startDate and endDate
          displayDate
          startDate
          slug
          shortDescription {
            text: shortDescription
          }
          location {
            __typename
            ... on ContentfulLocation {
              title
            }
            ... on ContentfulRichTextSection {
              richText {
                raw
              }
            }
          }
          hero {
            title
            image {
              title
              alt: altText
              asset {
                # TODO resizingBehavior --> gatsbyImageData(layout: FIXED, width: 510, height: 340, quality: 85, transformOptions: { fit: "cover" })
                gatsbyImageData(layout: FIXED, width: 510, height: 340, quality: 85, resizingBehavior: FILL)
              }
            }
          }
        }
      }
    }

    allContentfulNews(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          updatedAt
          title
          slug
          id
          date
          shortDescription {
            text: shortDescription
          }
          image {
            title
            alt: altText
            asset {
              gatsbyImageData(layout: CONSTRAINED, quality: 85, resizingBehavior: FILL)
            }
          }
        }
      }
    }

    allContentfulDealer(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          telephone {
            telephone
          }
          title
          address {
            address
          }
          mapUrl
          location {
            lat
            lon
          }
          filters
        }
      }
    }

    allContentfulAdvice(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          title
          shortDescription {
            text: shortDescription
          }
          slug
          image {
            asset {
              gatsbyImageData(layout: FIXED, height: 670, width: 670, quality: 80)
            }
          }
        }
      }
    }

    allContentfulAdvicesSection(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          __typename
          shortDescription {
            text: shortDescription
          }
          title
          cta {
            internalEntry {
              ...FragmentNavigationLink
            }
            title
            externalUrl
          }
          advices {
            __typename
            ... on ContentfulAdvice {
              id
              slug
              title
              image {
                asset {
                  gatsbyImageData(layout: FIXED, height: 670, width: 670, quality: 80)
                }
              }
              shortDescription {
                text: shortDescription
              }
            }
          }
        }
      }
    }

    allContentfulProduct(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          id
          title
          price
          category {
            slug
          }
          subcategory {
            slug
          }
          enquiryCta {
            externalUrl
            internalEntry {
              ...FragmentNavigationLink
            }
            title
          }
          shortDescription {
            text: shortDescription
          }
          image {
            title
            alt: altText
            asset {
              gatsbyImageData(layout: CONSTRAINED, quality: 85)
            }
          }
          document {
            title
            asset {
              description
              file {
                contentType
                url
                details {
                  size
                }
                fileName
              }
            }
          }
          slug
          cardCTAText
          linkToDetailPage
          linkToCustomPage {
            externalUrl
            internalEntry {
              ...FragmentNavigationLink
            }
            title
          }
          additionalDocuments {
            id
            title
            asset {
              description
              file {
                contentType
                url
                details {
                  size
                }
                fileName
              }
            }
          }
          contentsSection {
            raw
            references {
              __typename
              ... on ContentfulAdvice {
                contentful_id
                slug
              }

              ... on ContentfulBasicPage {
                contentful_id
                slug
              }

              ... on ContentfulEvent {
                contentful_id
                slug
              }

              ... on ContentfulNews {
                contentful_id
                slug
              }

              ... on ContentfulProduct {
                contentful_id
                slug
              }

              ... on ContentfulAsset {
                contentful_id
                file {
                  url
                }
              }

              ... on ContentfulAttachment {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                asset {
                  file {
                    url
                    details {
                      size
                    }
                    contentType
                  }
                }
              }

              ... on ContentfulBlockquote {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                quote {
                  text: quote
                }
                author
                authorInfo
                image {
                  title
                  alt: altText
                  asset {
                    file {
                      url
                    }
                    gatsbyImageData
                  }
                }
              }

              ... on ContentfulImage {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                alt: altText
                asset {
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                  gatsbyImageData
                }
              }

              ... on ContentfulMediaBlock {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                shortDescription {
                  text: shortDescription
                }
                caption {
                  text: caption
                }
                orientation
                media {
                  ... on ContentfulImage {
                    __typename
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    title
                    alt: altText
                    asset {
                      file {
                        url
                      }
                      portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                      squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                      landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                    }
                  }

                  ... on ContentfulVideo {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    videoUrl
                  }
                }
              }

              ... on ContentfulShortBanner {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                image {
                  title
                  alt: altText
                  asset {
                    file {
                      url
                      details {
                        image {
                          width
                          height
                        }
                      }
                    }
                    gatsbyImageData
                  }
                }
                cta {
                  title
                  internalEntry {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }
                  }
                  externalUrl
                }
              }

              ... on ContentfulTextSectionHighlight {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                richTextContents {
                  raw
                  references {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }

                    ... on ContentfulAsset {
                      contentful_id
                      file {
                        url
                      }
                    }
                  }
                }
              }

              ... on ContentfulVideo {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                videoUrl
              }

              ... on ContentfulRichTextSection {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                alignment
                richText {
                  raw
                  references {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }

                    ... on ContentfulAsset {
                      contentful_id
                      file {
                        url
                      }
                    }

                    ... on ContentfulAttachment {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      asset {
                        file {
                          url
                          details {
                            size
                          }
                          contentType
                        }
                      }
                    }

                    ... on ContentfulBlockquote {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      quote {
                        text: quote
                      }
                      author
                      authorInfo
                      image {
                        title
                        alt: altText
                        asset {
                          file {
                            url
                          }
                          gatsbyImageData
                        }
                      }
                    }

                    ... on ContentfulImage {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      alt: altText
                      asset {
                        file {
                          url
                          details {
                            image {
                              width
                              height
                            }
                          }
                        }
                        gatsbyImageData
                      }
                    }

                    ... on ContentfulMediaBlock {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      shortDescription {
                        text: shortDescription
                      }
                      caption {
                        text: caption
                      }
                      orientation
                      media {
                        ... on ContentfulImage {
                          __typename
                          contentful_id
                          sys {
                            contentType {
                              sys {
                                id
                              }
                            }
                          }
                          title
                          alt: altText
                          asset {
                            file {
                              url
                            }
                            portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                            squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                            landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                          }
                        }

                        ... on ContentfulVideo {
                          contentful_id
                          sys {
                            contentType {
                              sys {
                                id
                              }
                            }
                          }
                          videoUrl
                        }
                      }
                    }

                    ... on ContentfulShortBanner {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      image {
                        title
                        alt: altText
                        asset {
                          file {
                            url
                            details {
                              image {
                                width
                                height
                              }
                            }
                          }
                          gatsbyImageData
                        }
                      }
                      cta {
                        title
                        internalEntry {
                          __typename
                          ... on ContentfulAdvice {
                            contentful_id
                            slug
                          }

                          ... on ContentfulBasicPage {
                            contentful_id
                            slug
                          }

                          ... on ContentfulEvent {
                            contentful_id
                            slug
                          }

                          ... on ContentfulNews {
                            contentful_id
                            slug
                          }

                          ... on ContentfulProduct {
                            contentful_id
                            slug
                          }
                        }
                        externalUrl
                      }
                    }

                    ... on ContentfulTable {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      table {
                        tableData
                      }
                      fixFirstColumn
                    }

                    ... on ContentfulTextSectionHighlight {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      richTextContents {
                        raw
                        references {
                          __typename
                          ... on ContentfulAdvice {
                            contentful_id
                            slug
                          }

                          ... on ContentfulBasicPage {
                            contentful_id
                            slug
                          }

                          ... on ContentfulEvent {
                            contentful_id
                            slug
                          }

                          ... on ContentfulNews {
                            contentful_id
                            slug
                          }

                          ... on ContentfulProduct {
                            contentful_id
                            slug
                          }

                          ... on ContentfulAsset {
                            contentful_id
                            file {
                              url
                            }
                          }
                        }
                      }
                    }

                    ... on ContentfulVideo {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      videoUrl
                    }
                  }
                }
              }
            }
          }
          isBrand
          logo {
            title
            alt: altText
            asset {
              fixed(width: 150, quality: 80) {
                ...QueryImageFixed
              }
              gatsbyImageData(layout: FIXED, width: 150, quality: 80)
            }
          }
          categories
          filters
          address {
            text: address
          }
          productsAndServices {
            text: productsAndServices
          }
        }
      }
    }

    # TODO Check if can be removed
    allContentfulCategory(
      filter: {
        node_locale: { eq: $node_locale }
        metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
      }
    ) {
      edges {
        node {
          slug
          id
          title
          image {
            title
            alt: altText
            asset {
              #  fluid(resizingBehavior: FILL, quality: 85) {
              #    ...QueryImageFluid
              #  }
              gatsbyImageData(layout: CONSTRAINED, quality: 85)
            }
          }
          shortDescription {
            text: shortDescription
          }
          cta {
            externalUrl
            internalEntry {
              ...FragmentNavigationLink
            }
            title
          }
        }
      }
    }
  }
`;
