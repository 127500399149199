// Implement here common utilities

export const parseModule = (module: any) => {
  if (module === null) {
    return null;
  }

  // eslint-disable-next-line no-underscore-dangle
  switch (module.__typename) {
    case 'ContentfulHeroSection':
      return { type: 'hero-section', ...module };
    case 'ContentfulInfoSection':
      return { type: 'info-section', ...module };
    case 'ContentfulUpcomingEvents':
      return { type: 'upcoming-events-section', ...module };
    case 'ContentfulLatestNewsSection':
      return { type: 'latest-news-section', ...module };
    case 'ContentfulProductsServicesSection':
      return { type: 'products-services-section', ...module };
    // TODO @deprecated to be removed in the future
    case 'ContentfulCtaSection':
      return { type: 'cta-section', ...module };
    // Replacement for ContentfulCtaSection
    case 'ContentfulCtaBanner':
      return { type: 'cta-banner', ...module };
    case 'ContentfulProductCategoriesSection':
      return { type: 'product-categories-section', ...module };
    // TODO @deprecated to be removed in the future
    case 'ContentfulTextSection':
      return { type: 'text-section', ...module };
    // NEW
    case 'ContentfulRichTextSection':
      return { type: 'text-section', ...module };
    case 'ContentfulContactsSection':
      return { type: 'contacts-section', ...module };
    case 'ContentfulFindADealerSection':
      return { type: 'find-a-dealer-section', ...module };
    case 'ContentfulSearchResultsSection':
      return { type: 'search-results-section', ...module };
    case 'ContentfulAdviceListingSection':
      return { type: 'advice-listing-section', ...module };
    case 'ContentfulAdvicesSection':
      return { type: 'advice-section', ...module };
    case 'ContentfulProductsListingTableSection':
      return { type: 'products-listing-table-section', ...module };
    case 'ContentfulNewsListingSection':
      return { type: 'news-listing-section', ...module };
    case 'ContentfulEventsListingSection':
      return { type: 'events-listing-section', ...module };
    case 'ContentfulPastEventsSection':
      return { type: 'past-events-section', ...module };
    case 'ContentfulProductListingSection':
      return { type: 'product-listing-section', ...module };
    case 'ContentfulCategoryListingSection':
      return { type: 'category-listing-section', ...module };
    case 'ContentfulAttachment':
      return { type: 'document', ...module };
    case 'ContentfulImageAndText':
      return { type: 'image-and-text', ...module };
    case 'ContentfulSubcategoryListingSection':
      return { type: 'subcategory-listing-section', ...module };
    case 'ContentfulShortBanner':
      return { type: 'short-banner', ...module };
    // TODO @deprecated to be removed in the future
    case 'ContentfulMedia':
      return { type: 'media', ...module };
    // Replacement for ContentfulMedia
    case 'ContentfulMediaBlock':
      return { type: 'media', ...module };
    case 'ContentfulTable':
      return { type: 'table', ...module };
    case 'ContentfulCustomBlocksSection':
      return { type: 'custom-blocks-section', ...module };
    default:
  }

  return null;
};

export const parseModules = (modules: any[]) => {
  return modules.map((module: any) => parseModule(module));
};
