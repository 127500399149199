import extractCta from '../Cta';
import { ContentfulNewsConnection } from '../../generated/gatsby.types';
import transformNewsItems from '../NewsItems';
import extractShortDescription, { ItemType } from '../ShortDescription';

const transformLatestNewsSection = (item: ItemType, news: ContentfulNewsConnection) => {
  return {
    __typename: 'LatestNewsSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    latestNews: transformNewsItems(news.edges)
      .filter(newsItem => newsItem && newsItem.date)
      .sort((firstNewsItem: any, secondNewsItem: any) => {
        // eslint-disable-next-line no-nested-ternary
        return firstNewsItem.date > secondNewsItem.date ? -1 : firstNewsItem.date < secondNewsItem.date ? 1 : 0;
      })
      .slice(0, 3),
    cta: extractCta(item),
  };
};

export default transformLatestNewsSection;
