import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';

const transformRichTextSection = (item: any, locale: string) => {
  return {
    __typename: 'Text',
    key: item.id,
    data: item.richText ? richTextToJson(item.richText, locale).json : null,
  };
};

export default transformRichTextSection;
