import extractShortDescription from '../ShortDescription';

const extractBrands = products => {
  return products
    .filter(productEdge => productEdge.node.isBrand)
    .map(productEdge => {
      const productItem = productEdge.node;

      return {
        id: productItem.id,
        title: productItem.title,
        shortDescription: extractShortDescription(productItem),
        media: productItem.image,
        address: productItem.address?.text || '',
        productsAndServices: productItem.productsAndServices?.text || '',
        richText: productItem.contentsSection || {},
        logo:
          productItem.logo && productItem.logo.asset && productItem.logo?.asset?.fixed
            ? productItem.logo?.asset?.fixed.src
            : null,
        categories: productItem.categories || [],
        filters: productItem.filters || [],
        cta:
          productItem && productItem.enquiryCta
            ? {
                label: productItem.enquiryCta.title,
                to: productItem.enquiryCta.internalEntry
                  ? productItem.enquiryCta.internalEntry.slug
                  : productItem.enquiryCta.externalUrl,
              }
            : null,
      };
    });
};

const transformBrandListingSection = (item, products) => {
  const brands = extractBrands(products.edges);
  const categories = [...new Set(brands.map(brand => brand.categories).flat())];
  const filters = [...new Set(brands.map(brand => brand.filters).flat())];

  return {
    __typename: 'BrandListing',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    brands,
    categories,
    filters,
  };
};

export default transformBrandListingSection;
