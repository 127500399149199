/* eslint-disable no-underscore-dangle */
import { getSrc } from 'gatsby-plugin-image';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import extractShortDescription from '../ShortDescription';
import {
  ContentfulEventConnection,
  ContentfulEventEdge,
  ContentfulLocationContentfulMagentoStoreContentfulRichTextSectionUnion,
} from '../../generated/gatsby.types';

const getEventLocation = (location: ContentfulLocationContentfulMagentoStoreContentfulRichTextSectionUnion) => {
  if (location.__typename === 'ContentfulLocation') {
    return `${location.title} ${location.address?.address}`;
  }

  if (location.__typename === 'ContentfulRichTextSection') {
    return location?.richText?.raw ? documentToPlainTextString(JSON.parse(location.richText.raw)) : '';
  }

  return null;
};

const extractEvents = (edges: Array<ContentfulEventEdge>) => {
  return edges
    .map((edge: ContentfulEventEdge) => {
      const eventItem = edge.node;

      if (!eventItem) {
        return null;
      }

      return {
        id: eventItem.id,
        title: eventItem.hero?.title,
        image: getSrc(eventItem.hero?.image?.asset?.gatsbyImageData),
        displayDate: eventItem.displayDate,
        location: eventItem.location ? getEventLocation(eventItem.location) : '',
        shortDescription: extractShortDescription(eventItem),
        link: eventItem.slug || '/',
      };
    })
    .filter(Boolean);
};

const transformEventsListingSection = (item: any, events: ContentfulEventConnection) => {
  return {
    __typename: 'EventsListingSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    events: extractEvents(events.edges),
  };
};

export default transformEventsListingSection;
